<template>
  <div>
    <b-card class="bg-light-primary">
      <b-row style="margin-top :-10px; margin-bottom :-20px">
        <b-col md="9">
          <b-form-group>
            <v-select v-model="id_dinas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_dinas"
              @input="loadData()" :options="opd" />
          </b-form-group>
        </b-col>
        <b-col md="0">
          <!-- basic -->
          <!-- <b-form-group>
            <b-form-input id="basicInput" placeholder="Pencarian Paket" />
          </b-form-group> -->
        </b-col>
        <b-col md="3">
          <b-button block variant="primary" v-b-modal.modal-1 @click="defaultData()">
            <feather-icon size="15" icon="PlusIcon" /> Tambah Paket
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <daftar-paket :items=items @detailPaket=detailPaket @deleteData=deleteData></daftar-paket>
    </b-card>
    <tambah-paket :opd=opd :rs_kec=rs_kec @insertPaket=insertPaket @load_kegiatan="load_kegiatan"
      @load_sub_kegiatan="load_sub_kegiatan" :kegiatan=kegiatan :sub_kegiatan=sub_kegiatan
      :inputs="inputs"></tambah-paket>
    <detai-kegiatan :opd=opd :rs_kec=rs_kec :dataEdit=dataEdit @updatePaket=updatePaket></detai-kegiatan>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import axios from '@/config/Axios'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DaftarPaket from './componet/DaftarPaket.vue'
import TambahPaket from './componet/TambahPaket.vue'
import DetaiKegiatan from './componet/DetailKegiatan.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    vSelect,
    DaftarPaket,
    TambahPaket,
    DetaiKegiatan,
  },
  mixins: [Base],
  data() {
    return {
      id_master_kode_dinas: "",
      items: [],
      opd: [],
      rs_kec: [],
      dataEdit: {},
      kegiatan: [],
      sub_kegiatan: [],
      inputs: {}

    }
  },
  mounted() {
    this.load();
    this.kecamatan();
    this.daftar_opd();
  },
  methods: {
    defaultData() {
      this.kegiatan = [];
      this.sub_kegiatan = [];
      this.input = {};
    },
    async load_kegiatan(val) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_paket/load_kegiatan',
        data: {
          id_master_kode_dinas: val.id_master_kode_dinas
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kegiatan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_sub_kegiatan(val) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_paket/load_sub_kegiatan',
        data: {
          id_kegiatan: val.id_kegiatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.sub_kegiatan = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },


    async updatePaket(value) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/input/data_paket/update',
        data: {
          id_master_kode_dinas: value.id_master_kode_dinas.id_master_kode_dinas,
          id_kegiatan: value.id_kegiatan.id_kegiatan,
          id_sub_kegiatan: value.id_sub_kegiatan.id_sub_kegiatan,
          judul: value.judul,
          jenis_kegiatan: value.jenis_kegiatan,
          id_kecamatan: value.id_kecamatan,
          anggaran: value.anggaran,
          keterangan: value.keterangan,
          id: value.id,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "info", response.data.message);
          self.load();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async deleteData(value) {
      if (confirm("apakah andayakin akan menghapus data ini")) {
        const self = this;
        await axios({
          method: 'POST',
          url: '/api/input/data_paket/delete',
          data: {
            id: value.id
          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.notification('info', "Info", response.data.message);
            self.load();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },
    detailPaket(value) {
      this.dataEdit = value
    },
    loadData() {
      this.id_master_kode_dinas = this.id_dinas.id_master_kode_dinas;
      this.load();
    },

    async load() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_paket/load',
        data: {
          id_master_kode_dinas: self.id_master_kode_dinas
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.items = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async daftar_opd() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_paket/daftar_opd',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.opd = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async kecamatan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_paket/kecamatan',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_kec = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async insertPaket(input) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/input/data_paket/insert',
        data: {
          id_master_kode_dinas: input.id_master_kode_dinas.id_master_kode_dinas,
          id_kegiatan: input.id_kegiatan.id_kegiatan,
          id_sub_kegiatan: input.id_sub_kegiatan.id_sub_kegiatan,
          judul: input.judul,
          jenis_kegiatan: input.jenis_kegiatan,
          id_kecamatan: input.id_kecamatan.value,
          anggaran: input.anggaran,
          keterangan: input.keterangan,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('info', "info", response.data.message);
          self.load();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }
}
</script>

<style></style>