<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-2" size="lg" title="Edit Paket Sub Kegiatan" ok-only ok-title="Accept" hide-footer
            no-close-on-backdrop>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col md="12">
                        <b-form-group label="NamaDinas" label-for="basicInput">
                            <h6>{{ input.kode_dinas }} {{ input.nama_dinas }}</h6>
                        </b-form-group>

                        <b-form-group label="Tahun Anggaran" label-for="basicInput">
                            <h6>{{ input.th_anggaran }} </h6>
                        </b-form-group>
                        <b-form-group label="Nama Kegiatan" label-for="basicInput">
                            <h6>{{ input.kode_kegiatan }} {{ input.nama_kegiatan }}</h6>
                        </b-form-group>
                        <b-form-group label="Nama Sub Kegiatan" label-for="basicInput">
                            <h6>{{ input.kode_sub_kegiatan }} {{ input.nama_sub_kegiatan }}</h6>
                        </b-form-group>




                        <b-form-group label="Jenis Kegiatan" label-for="basicInput">
                            <validation-provider #default="{ errors }" name="Jensi kegiatan" rules="required">
                                <b-form-select v-model="input.jenis_kegiatan" :options="options"
                                    :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Lokasi Kecamatan" label-for="basicInput">
                            <validation-provider #default="{ errors }" name="Lokasi Kecamatan" rules="required">
                                <b-form-select v-model="input.id_kecamatan" :options="rs_kec"
                                    :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>


                        <!-- 
                        <b-form-group label="Lokasi Kecamatan">
                            <validation-provider #default="{ errors }" name="Lokasi Kecamatan" rules="required">
                                <v-select v-model="input.id_kecamatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="nm_wil" :options="rs_kec" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group> -->


                        <b-form-group label="Besaran Anggaran" label-for="basicInput">
                            <validation-provider #default="{ errors }" name="Anggaran " rules="required">
                                <CurrencyInput :state="errors.length > 0 ? false : null" class="form-control"
                                    v-model="input.anggaran" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Keterangan" label-for="basicInput">
                            <b-form-textarea id="textarea-default" placeholder="Keterangan" rows="3"
                                v-model="input.keterangan" />
                        </b-form-group>

                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col>
                        <b-button variant="primary" @click.prevent="validationForm"> Update </b-button>
                        <b-button variant="warning" class="ml-1" @click="close()"> Batal</b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required
} from '@validations'
import {
    BModal,
    BButton,
    VBModal,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import CurrencyInput from './CurrencyInput.vue'

export default {
    components: {
        BButton,
        ValidationProvider,
        ValidationObserver,
        BModal,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormGroup,
        BFormInput,
        BFormSelect,
        CurrencyInput,
        BFormTextarea,
        BCardText
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    data() {
        return {
            required,
            option: [],
            kecamatan: [], options: [{
                value: null,
                text: 'Silahkan Pilih Jenis kegiatan'
            },
            {
                value: 'Fisik',
                text: 'Kegiatan Fisik'
            },
            {
                value: 'Konsultasi',
                text: 'Kegiatan Konsultasi'
            },
            {
                value: 'Pengadaan',
                text: 'Kegiatan Pengadaan'
            },
            ],
        }
    },
    props: {
        dataEdit: {},
        opd: {},
        rs_kec: {}
    },
    computed: {
        input: {
            get: function () {
                return this.dataEdit;
            },
            set: function (newValue) {
                return this.dataEdit = newValue;
            },
        },
    },
    emits: ["c"],
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$root.$emit('bv::hide::modal', 'modal-2');
                    this.$emit("updatePaket", this.input);
                    this.input = {};
                }
            })
        },
        close() {
            this.$root.$emit('bv::hide::modal', 'modal-2');
        }
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>