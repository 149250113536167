<template>
    <div>

        <!-- basic modal -->
        <b-modal id="modal-1" size="lg" title="Tambah Paket Sub Kegiatan" ok-only ok-title="Accept" hide-footer
            no-close-on-backdrop>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col md="12">
                        <b-form-group label="SKPD / OPD">
                            <validation-provider #default="{ errors }" name="SKPD" rules="required">
                                <v-select v-model="input.id_master_kode_dinas"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_dinas" :options="opd"
                                    @input="load_kegiatan" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Kegiatan">
                            <validation-provider #default="{ errors }" name="Kegiatan" rules="required">
                                <v-select v-model="input.id_kegiatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="nama_kegiatan" :options="kegiatan" @input="load_sub_kegiatan"
                                    :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Sub Kegiatan">
                            <validation-provider #default="{ errors }" name="Sub Kegiatan" rules="required">
                                <v-select v-model="input.id_sub_kegiatan"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_sub_kegiatan"
                                    :options="sub_kegiatan" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group label="Jenis Kegiatan" label-for="basicInput">
                            <validation-provider #default="{ errors }" name="Jensi kegiatan" rules="required">
                                <b-form-select v-model="input.jenis_kegiatan" :options="options"
                                    :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Lokasi Kecamatan">
                            <validation-provider #default="{ errors }" name="Lokasi Kecamatan" rules="required">
                                <v-select v-model="input.id_kecamatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="text" :options="rs_kec" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group label="Pagu Anggaran" label-for="basicInput">
                            <validation-provider #default="{ errors }" name="Anggaran " rules="required">
                                <CurrencyInput :state="errors.length > 0 ? false : null" class="form-control"
                                    v-model="input.anggaran" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Keterangan" label-for="basicInput">
                            <b-form-textarea id="textarea-default" placeholder="Keterangan" rows="3"
                                v-model="input.keterangan" />
                        </b-form-group>

                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col>
                        <b-button variant="primary" @click.prevent="validationForm"> Simpan</b-button>
                        <b-button variant="warning" class="ml-1"> Batal</b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required
} from '@validations'
import {
    BModal,
    BButton,
    VBModal,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import CurrencyInput from './CurrencyInput.vue'

export default {
    components: {
        BButton,
        ValidationProvider,
        ValidationObserver,
        BModal,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormGroup,
        BFormInput,
        BFormSelect,
        CurrencyInput,
        BFormTextarea,
        BCardText
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    props: {
        opd: {},
        rs_kec: {},
        sub_kegiatan: {},
        kegiatan: {},
        inputs: {}
    },
    emits: ['insertPaket', 'load_kegiatan', 'load_sub_kegiatan'],
    data() {
        return {
            required,
            options: [{
                value: null,
                text: 'Silahkan Pilih Jenis kegiatan'
            },
            {
                value: 'Fisik',
                text: 'Kegiatan Fisik'
            },
            {
                value: 'Konsultasi',
                text: 'Kegiatan Konsultasi'
            },
            {
                value: 'Pengadaan',
                text: 'Kegiatan Pengadaan'
            },
            ],
        }
    },
    computed: {
        input: {
            get: function () {
                return this.inputs;
            },
            set: function (newValue) {
                return this.inputs = newValue;
            }
        },
    },
    methods: {
        load_sub_kegiatan() {
            this.$emit('load_sub_kegiatan', this.input.id_kegiatan);
        },
        load_kegiatan() {
            this.$emit('load_kegiatan', this.input.id_master_kode_dinas)
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$root.$emit('bv::hide::modal', 'modal-1');
                    this.$emit("insertPaket", this.input);
                    this.input = {};
                }
            })
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>